<script>
/* COMPOSANTS */
import AkLoader from '@components/general/AkLoader.vue';
import AkAddress from '@components/v2/general/AkAddress.vue';
import AkButtonAction from '@components/v2/general/AkButtonAction.vue';
import AkButtonActionValidation from '@components/v2/general/AkButtonActionValidation.vue';

/* MIXINS */
import utilsMixin from '@mixins/utilsMixin';
import sidebarMixin from '@mixins/sidebarMixin';
import loaderMixin from '@mixins/loaderMixin';
import civilitiesConst from '@mixins/const/civilitiesConst';

/* SERVICES */
import accommodationContactService from '@services/accommodationContactService';

export default {
  components: {AkLoader, AkAddress, AkButtonAction, AkButtonActionValidation},
  mixins: [loaderMixin, sidebarMixin, utilsMixin, civilitiesConst],
  props: {
    modelValue: {required: true},
    callback: {
      type: Array,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      contact: undefined,
      contactTypes: undefined,
    };
  },
  mounted() {
    this.$refs.loader.load();

    accommodationContactService
      .accommodationContact(this.currentValue)
      .then((data) => {
        this.contact = data;
      })
      .finally(() => this.$refs.loader.hide());
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit('update:modelValue', val);
      },
    },
    titlePopup() {
      if (!this.contact) return '';
      return this.contact.kind === 'INTERNAL'
        ? this.$t('contact.internal_confirm_title')
        : this.$t('contact.external_confirm_title');
    },
    messagePopup() {
      if (!this.contact) return '';
      return this.contact.kind === 'INTERNAL'
        ? this.$t('contact.internal_confirm_delete')
        : this.$t('contact.external_confirm_delete');
    },
  },
  methods: {
    delete() {
      this.callback[0]();
      this.hideSidebar();
    },
    update() {
      this.$router.push({
        name: 'accommodationContactUpdate',
        params: {accommodationId: this.$route.params.accommodationId, contactId: this.currentValue},
      });
      this.hideSidebar();
    },
  },
};
</script>

<template>
  <AkLoader ref="loader" :scale="2" color="var(--color-primary)" message="Récupération en cours">
    <template #content>
      <div v-if="contact" class="flex flex-col md:gap-4 gap-2">
        <div class="grid grid-cols-2 md:gap-4 gap-2">
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">{{ $t('accommodationContact.type') }}</span>
            <span class="font-inter font-medium text-gray text-md">{{ contact.type2Display }}</span>
          </div>

          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.civility') }}</span>
            <span class="font-inter font-medium text-gray text-md">{{
              this.displayForCivilityConst(contact.civility) ?? '---'
            }}</span>
          </div>

          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.lastName') }}</span>
            <span class="font-inter font-medium text-gray text-md">{{ contact.lastName ?? '---' }}</span>
          </div>

          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.firstName') }}</span>
            <span class="font-inter font-medium text-gray text-md">{{ contact.firstName ?? '---' }}</span>
          </div>
        </div>

        <!-- MAP -->
        <AkAddress v-model="this.contact" />

        <div class="grid grid-cols-2 md:gap-4 gap-2">
          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.email') }}</span>
            <span class="font-inter font-medium text-gray text-md">{{ contact.email ?? '---' }}</span>
          </div>

          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.phone') }}</span>
            <span class="font-inter font-medium text-gray text-md">{{ contact.phone ?? '---' }}</span>
          </div>

          <div class="flex flex-col gap-1">
            <span class="font-inter font-semibold text-dark text-base">{{ $t('contact.phone2') }}</span>
            <span class="font-inter font-medium text-gray text-md">{{ contact.phone2 ?? '---' }}</span>
          </div>
        </div>

        <div class="grid grid-cols-2 md:gap-4 gap-2 col-span-2">
          <AkButtonActionValidation
            ref="btnDelete"
            :label="$t('delete')"
            :titlePopup="this.titlePopup"
            :messagePopup="this.messagePopup"
            danger
            @click="this.delete" />
          <AkButtonAction ref="btnUpdate" :label="$t('update')" @click="this.update" />
        </div>
      </div>
    </template>
  </AkLoader>
</template>
